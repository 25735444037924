<template>
  <v-container fluid>
    <PageHeader title="Calendrier" :contextStyle="contextStyle">
      <template #right>
        <v-btn-toggle v-model="type">
          <v-btn value="month" small>
            <span>Mois</span>
          </v-btn>
          <v-btn value="week" small>
            <span>Semaine</span>
          </v-btn>
          <v-btn value="day" small>
            <span>Jour</span>
          </v-btn>
        </v-btn-toggle>
      </template>
    </PageHeader>
    <Loader :loading="loading" />
    <Error :show="false" text="Erreur lors du chargement des évènements du calendrier." />
    <Alert :show="error" outlined text="Erreur lors du chargement des évènements du calendrier." />
    <v-row>
      <v-col cols="12">
        <v-sheet id="navigation"
                 tile
                 height="54"
                 class="d-flex align-center"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer/>
          <h2 v-if="$refs.calendar">{{ $refs.calendar.title }}</h2>
          <v-spacer/>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet id="calendar"
                 height="600"
        >
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="calendarFormattedTasks"
            show-week
            :event-overlap-threshold="30"
            event-more-text="Voir tout"
            @click:more="viewDay"
            @click:event="edit"
            @click:date="viewDay"
          >
            <template #event="{ event, timed, eventSummary }">
              <TaskCalendarEvent :event="event" />
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import Loader from '@/components/ui/Loader'
import Error from '@/components/ui/Error'
import Alert from '@/components/ui/Alert'
import TaskMixin from '@/mixins/TaskMixin'
import TaskCalendarEvent from '@/components/calendar/TaskCalendarEvent'

export default {
  props: ['contextStyle'],
  components: {
    TaskCalendarEvent,
    PageHeader,
    Loader,
    Error,
    Alert
  },
  mixins: [TaskMixin],
  data() {
    return {
      tasks: [],
      type: 'month',
      weekday: [1, 2, 3, 4, 5, 6, 0],
      value: this.$date().format('DD/MM/YYYY'),
      loading: false,
      error: false
    }
  },
  computed: {
    dayEvents () {
      return this.calendarFormattedTasks.filter((t) => {
        return (this.$date(t.start).isBefore(this.$date(this.value), 'day')
          && this.$date(t.end).isAfter(this.$date(this.value), 'day'))
          || this.$date(t.start).isSame(this.$date(this.value), 'day')
      })
    }
  },
  async mounted() {
    await this.loadData()
    this.$refs.calendar.checkChange()
    this.$forceUpdate()
  },
  methods: {
    viewDay (data) {
      this.value = data.date
      this.type = 'day'
      this.$forceUpdate()
    },
    async edit (item) {
      item.event = await this.fetchTask(item.event.tsk_id)
      // here we use item.event because v-calendar moves all original properties in 'event' property
      this.setTask(item.event)
    },
    async loadData() {
      try {
        this.error = false
        this.loading = true
        this.tasks = await this.fetchTasks()
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
