<template>
  <div class="px-1" :title="event.tsk_obs">
    <v-icon v-if="event.late" color="white" small class="mr-1">mdi-calendar-clock</v-icon>
    <span class="font-weight-bold">{{ event.types.reduce(function(a, b) {return a + ["", ", "][+!!a.length] + b.typ_label;}, "") }}</span>
  </div>
</template>

<script>
import TaskMixin from '@/mixins/TaskMixin'

export default {
  mixins: [TaskMixin],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  methods: {

  }
}
</script>
